$(document).ready(function(){
  let selection = $('.js-choose-verdicts'),
      photos = $('.js-verdicts-photo');

      photos.hide();

      $('.js-verdicts-photo[data-verdicts="aviation"]').show();


  selection.on('click', function(e){
    e.preventDefault();

    let choice = $(this).data('verdicts');

    selection.removeClass('is-active');
    photos.hide();

    $('.js-verdict-items div').hide();
    $(this).addClass('is-active');
    $('.js-verdict-items [data-verdicts="'+ choice +'"]').show();
    $('.js-verdicts-photo[data-verdicts="'+ choice +'"]').show();
  });
})
