$(document).ready(function(){

  $('.js-team-bio').hide();

  $('.l-team__member-photo, .l-team__member-button').each(function(){

    $(this).on('click', function(e){
      e.preventDefault();

      var id = $(this).data("id")

      console.log(id);

      $('.js-team-bio').hide();
      $('#' + id).show();

      var target = $('#' + id);

      $('html, body').animate({
        scrollTop: target.offset().top - 100
      }, 0);

    })
  })

});
