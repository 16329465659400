$(document).ready(function(){

  function navPracticeAreas(screen){
		// due to skew transform bug, this pulls out the practice areas
	  // sub menu and places it outside of the parent li element, and then
	  // through magic allows practice areas to still hover the menu in place

	  let ul = $('.l-nav__items'),
	      li = ul.find('.menu-item-has-children'),
	      sub = li.find('.sub-menu');


				if(screen == 'desktop'){
					ul.prepend(sub);
					console.log(screen)
				} else {
					ul.find('.menu-item-has-children').append(sub)
					console.log(screen)
				}


	      li.hover(function(){
	        sub.css('display', 'flex');
	      }, function(){
	        if($('.sub-menu:hover').length != 0){
	          sub.css('display', 'flex');
	        } else {
	          sub.hide();
	        }
	      })

	      sub.hover(function(){
	        sub.css('display', 'flex');
	      }, function(){
	        sub.hide();
	      })
			}


	if($(window).width() > 768) {
		navPracticeAreas('desktop');
	} else {
		navPracticeAreas('mobile');
	}

	$(window).resize(function(){
		if($(window).width() > 768) {
			navPracticeAreas('desktop');
		} else {
			navPracticeAreas('mobile');
		}
	})


	$('.l-nav__hamburger .hamburger').on('click', function(){
		$(this).toggleClass('is-active');

		$('.l-nav__items').toggleClass('is-active');
	})
})
