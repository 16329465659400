$(document).ready(function(){

  let menu = $('.about-menu');
      menuOffset = menu.offset().top;

  function sticky(){
    let scrollTop = $(window).scrollTop();

    if (scrollTop > menuOffset) {
      menu.addClass('is-sticky');
    } else {
      menu.removeClass('is-sticky');
    }
  }

  sticky();

  $(window).scroll(function() {
    sticky();
  });


})
