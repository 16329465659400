$(document).ready(function(){

  let button = $('.js-search-btn'),
      searchContainer = $('.l-search');

  button.on('click', function(){
    searchContainer.css('display', 'flex');
  })

  $('body').on('click', '.l-search', function(e){
    if($(e.target).is('input')){
      return;
    } else {
      searchContainer.css('display', 'none');
    }
  })

})
